import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";


const LogoutPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.clear();
        navigate('/login', {replace: true});
    }, []);
};

export default LogoutPage;
