import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {Table, Row} from 'react-bootstrap';
import axios from "axios";

const Admin = (props) => {
    const [header, setHeader] = useState(null);
    const [data, setData] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL
    const table = props.table;

    useEffect(() => {

        axios.get(apiUrl + '/table/fields/' + table).then(r => {
            if (r.data.status) setHeader(r.data.data)
        })

        axios.get(apiUrl + '/table/data/' + table).then(r => {
            if (r.data.status) setData(r.data.data)
        })

    }, [apiUrl, table]);

    const headers = (header !== null) ? header.map((item, index) =>
        <th key={index}>{formatString(item.name)}</th>
    ) : null;

    function formatString(str) {
        str = str.replace(/_/g, ' ');
        let words = str.split(' ');
        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return words.join(' ');
    }


    console.log('data', data);

    return (
        <Fragment>
            <div className="card my-5">
                <div className="card-header">
                    <Row>
                        <div className="col-md-6">
                            <h4 className="card-title">{table}</h4>
                        </div>
                        <div className="col-md-6" align="end">
                            <Link className="btn btn-outline-secondary btn-sm" to={"/admin/import/" + table}>Import</Link>
                        </div>
                    </Row>
                </div>
                <div className="card-body ">
                    <Table hover responsive small>
                        <thead>
                        <tr>
                            {/*<th><Form.Check/></th>*/}
                            {headers}
                            {/*<th>Action</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {data !== null ? data.map((row, index) => (
                            <tr key={index}>
                                {/*<td><Form.Check/></td>*/}
                                {Object.keys(row).map((field, i) => (
                                    <td key={i}>{data[index][field]}</td>
                                ))}
                                {/*<td>*/}
                                {/*    <Button variant="outline-warning" size="sm" className="text-uppercase">*/}
                                {/*        <i className="bi bi-exclamation-triangle"></i> Edit*/}
                                {/*    </Button>*/}
                                {/*    <Button variant="outline-danger" size="sm" className="text-uppercase">*/}
                                {/*        <i className="bi bi-radioactive"></i> Delete*/}
                                {/*    </Button>*/}
                                {/*</td>*/}
                            </tr>
                        )) : null}
                        </tbody>
                    </Table>
                </div>
                {/*<div className="card-footer">*/}
                {/*    <select>*/}
                {/*        <option>10</option>*/}
                {/*        <option>100</option>*/}
                {/*        <option>1000</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
            </div>
        </Fragment>
    );
};

export default Admin;