import React, {Fragment} from 'react';
import {Navbar, Nav, NavDropdown, Container} from 'react-bootstrap';
import {
    Speedometer2,
    Truck,
    Database,
    FileEarmarkSpreadsheet,
    BoxArrowRight,
    FiletypeXlsx, CardList
} from "react-bootstrap-icons";

const HeaderComponent = () => {

    const handleDownloadPickingList = () => {
        const fileUrl = process.env.REACT_APP_API_URL + '/search/pickingList';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'pickingList.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDownloadPackedProducts = () => {
        const fileUrl = process.env.REACT_APP_API_URL + '/search/packedList';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'packedList.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Fragment>
            <header>
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Container fluid>
                        <Navbar.Brand href="#">ALTEXPRESS</Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarText"/>
                        <Navbar.Collapse id="navbarText">
                            <Nav className="me-auto mb-2 mb-lg-0">
                                <Nav.Link href="/admin"><Speedometer2/> Dashboard</Nav.Link>
                                <Nav.Link href="/admin/products"><Database/> Products</Nav.Link>
                                <Nav.Link href="/admin/shipments"><Truck /> Shipments</Nav.Link>
                                <Nav.Link href="/admin/packing_material"><FileEarmarkSpreadsheet /> Packing Material</Nav.Link>
                                <Nav.Link onClick={handleDownloadPickingList}><CardList /> Picking List</Nav.Link>
                                <Nav.Link onClick={handleDownloadPackedProducts}><FiletypeXlsx /> Export</Nav.Link>
                            </Nav>
                            <Nav className="mb-2 mb-lg-0">
                                <NavDropdown title="User" id="navbarScrollingDropdown" align="end">
                                    {/*<NavDropdown.Item href="#">Profile</NavDropdown.Item>*/}
                                    {/*<NavDropdown.Item href="#">Settings</NavDropdown.Item>*/}
                                    {/*<NavDropdown.Divider/>*/}
                                    <NavDropdown.Item href="/logout"><BoxArrowRight /> Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                {/*<section>*/}
                {/*    <Container>*/}
                {/*        <div className="row py-3">*/}
                {/*            <div className="col-md-6">*/}
                {/*                <h3 className="card-title">Table</h3>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-6 align-middle d-flex justify-content-md-end">*/}
                {/*                /!*<Breadcrumb>*!/*/}
                {/*                /!*    <Breadcrumb.Item href="#"><i className="bi bi-house"></i></Breadcrumb.Item>*!/*/}
                {/*                /!*    <Breadcrumb.Item href="#">Library</Breadcrumb.Item>*!/*/}
                {/*                /!*    <Breadcrumb.Item active>Data</Breadcrumb.Item>*!/*/}
                {/*                /!*</Breadcrumb>*!/*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </Container>*/}
                {/*</section>*/}
            </header>
        </Fragment>
    );
};

export default HeaderComponent;
