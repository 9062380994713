import React, {Fragment, useEffect, useRef, useState} from 'react';
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import {
    CheckCircle,
    ExclamationCircle,
    ArrowUpRight,
    ArrowDownLeft,
    Trash3
} from 'react-bootstrap-icons';

import {Button, ButtonGroup, Form, FormControl, FormGroup, FormLabel, Toast} from "react-bootstrap";
import axios from "axios";


const Admin = () => {

    const [query, setQuery] = useState('');
    const [queryProduct, setQueryProduct] = useState('');
    const [shipments, setShipments] = useState([])
    const [products, setProducts] = useState([])
    const [submit, setSubmit] = useState({})
    const [notification, setNotification] = useState({});
    const [requirementsMeet, setRequirementsMeet] = useState(false);

    const [loadingScanAwb, setLoadingScanAwb] = useState(false);
    const [loadingScanProducts, setLoadingScanProducts] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [location, setLocation] = useState('');

    const [packing, setPacking] = useState([]);

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current)
            inputRef.current.focus();
    }, []);

    // Changes

    const handleInputChange = (event) => {
        setQuery(event.target.value);

        compareRequirements()
    };

    const handleInputChangeProduct = (event) => {
        setQueryProduct(event.target.value);
    };

    const handleInputChangeList = (event) => {
        const {name, value} = event.target;
        const dataIndex = event.target.getAttribute('data-index');
        setSubmit((prevSubmit) => ({
            ...prevSubmit,
            [dataIndex]: {
                ...prevSubmit[dataIndex],
                [name]: parseInt(value),
            },
        }));
    };

    // Searches

    const handleSearchShipment = (event) => {
        event.preventDefault();
        setLoadingScanAwb(true);
        let toProducts = [];
        let toSubmit = [];
        axios.post(process.env.REACT_APP_API_URL + '/search/shipment', {search: query}).then(r => {
            if (r.data.status === true) {
                setShipments(r.data.data);
                r.data.data.map((value, index) => {
                    toProducts[index] = {
                        sku: value.sku,
                        qty: value.qty
                    }

                    return toProducts
                })

                setProducts(toProducts)

                r.data.data.map((value, index) => {
                    toSubmit[index] = {
                        sku: value.sku,
                        qty: 0
                    }
                    return toSubmit
                })
                setSubmit(toSubmit);

                if (inputRef.current)
                    inputRef.current.focus();

            } else {
                setNotification({type: false, message: r.data.msg})
            }

            setTimeout(() => {
                setNotification({});
            }, 10000);
            setLoadingScanAwb(false);
        })
    };

    const handleSearchProduct = (event) => {
        event.preventDefault();
        setLoadingScanProducts(true)

        const toSubmit = [...submit];
        let isProduct = false;
        products.map((product, index) => {
            if (product.sku === queryProduct) {
                toSubmit[index].qty += 1;
                isProduct = true;
            }
            return toSubmit
        })
        setSubmit(toSubmit);

        if (isProduct === false && queryProduct !== '')
            handleStatePacking();

        setQueryProduct("");
        setLoadingScanProducts(false)
        if (inputRef.current)
            inputRef.current.focus();

        compareRequirements()
    };

    const handleStatePacking = () => {
        const toPacking = [...packing];
        axios.get(process.env.REACT_APP_API_URL + '/search/packing/' + queryProduct).then(r => {
            if (r.data.status === true) {
                let packingExists = false;
                packing.map((pack, index) => {
                    if (pack.sku === queryProduct) {
                        packingExists = true;
                        toPacking[index].qty += 1;
                    }
                    return toPacking
                })

                if (packingExists === false) {
                    r.data.data.qty = 1;
                    toPacking.push(r.data.data);
                }

                setPacking(toPacking);
            } else {
                setNotification({type: false, message: 'SKU not found!'});
            }
            setTimeout(() => {
                setNotification({});
            }, 10000);
            setLoadingSubmit(false);
        });
        console.log(queryProduct);
    }

    // const handleKeyUp = (event) => {
    //     setLocation(event.target.value);
    // };

    // Submit

    function handleSubmit() {
        setLoadingSubmit(true);
        axios.post(process.env.REACT_APP_API_URL + '/search/shipmentSave', {
            awb: query,
            products: submit,
            parking: packing,
            location: location
        }).then(r => {
            if (r.data.status === true) {
                setProducts([]);
                setNotification({type: true, message: 'Data saved successfully'});
            } else {
                setNotification({type: false, message: 'Failed to save data'});
            }

            setTimeout(() => {
                setNotification({}); // Reset the notification after 5 seconds
            }, 10000);
            setLoadingSubmit(false);
        });
    }

    // Helpers

    const incrementQty = (index) => {
        const toSubmit = [...submit];
        toSubmit[index].qty += 1;
        setSubmit(toSubmit);
        // if (inputRef.current)
        //     inputRef.current.focus();

        compareRequirements()
    };

    const decrementQty = (index) => {
        const toSubmit = [...submit];
        toSubmit[index].qty -= 1;
        setSubmit(toSubmit);
        // if (inputRef.current)
        //     inputRef.current.focus();

        compareRequirements()
    };

    const removeItemByIndex = (index) => {
        setPacking(prevPacking => prevPacking.filter((_, i) => i !== index));
    };

    const compareRequirements = () => {

        let result = true;
        Object.keys(products).map(index => {
            if (products[index].qty !== submit[index].qty)
                result = false

            return null
        })

        setRequirementsMeet(result);
    }

    const handleDismiss = () => {
        setNotification({});
    };


    return (
        <Fragment>
            <HeaderComponent/>
            <main>
                {(notification.type || notification.type === true || notification.type === false) && (
                    <Toast onClose={handleDismiss}
                           className={`position-absolute top-0 end-0 mt-5 me-3 toast align-items-center ${notification.type === true ? 'bg-success-subtle' : 'bg-danger-subtle'}`}
                           role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div className="toast-body">
                                {notification.message}
                            </div>
                            <Button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast"
                                    aria-label="Close" onClick={handleDismiss}></Button>
                        </div>
                    </Toast>
                )}
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <Form onSubmit={handleSearchShipment} className="my-5">
                                    <FormLabel>Scan AWB</FormLabel>

                                    <ButtonGroup className="w-100">
                                        {products.length === 0 ? (
                                            <FormControl
                                                type="text"
                                                placeholder="Shipments AWB"
                                                className="mr-sm-2"
                                                name="searchAwb"
                                                value={query}
                                                onChange={handleInputChange}
                                                autoFocus
                                            />
                                        ) : (
                                            <FormControl
                                                type="text"
                                                placeholder="Shipments AWB"
                                                className="mr-sm-2"
                                                name="searchAwb"
                                                value={query}
                                                onChange={handleInputChange}
                                            />
                                        )}
                                        {(loadingScanAwb === false) ?
                                            <Button variant="primary" type="submit">Enter</Button>
                                            :
                                            <Button className="btn btn-primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status"
                                                      aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </Form>

                                {(products && products.length > 0) &&
                                    <>
                                        <Form onSubmit={handleSearchProduct} className="my-5">
                                            <FormLabel>Scan SKU / Code / Barcode / Packing Material</FormLabel>
                                            <ButtonGroup className="w-100">
                                                <FormControl
                                                    type="text"
                                                    value={queryProduct}
                                                    placeholder="SKU / Code / Barcode / Packing Material"
                                                    className="mr-sm-2"
                                                    name="searchProduct"
                                                    id="inputFocus"
                                                    ref={inputRef}
                                                    onChange={handleInputChangeProduct}
                                                />
                                                {(loadingScanProducts === false)
                                                    ? <Button variant="primary" type="submit">Enter</Button>
                                                    : <Button className="btn btn-primary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status"
                                                              aria-hidden="true"></span>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Button>
                                                }
                                            </ButtonGroup>
                                        </Form>

                                        <div className="my-5">
                                            <span>Products</span>
                                            <div className="card">
                                                <ul className="list-group list-group-flush">
                                                    {Object.keys(products).map(index => {
                                                        return (
                                                            <li className="list-group-item" key={index}>
                                                                <FormGroup className="row">

                                                                    <FormLabel
                                                                        className="col-5 col-form-label">
                                                                        {products[index].qty === submit[index].qty &&
                                                                            <CheckCircle className="me-1 text-success"/>
                                                                        }
                                                                        {(products[index].qty < submit[index].qty || submit[index].qty < 0) &&
                                                                            <ExclamationCircle
                                                                                className="me-1 text-danger"/>
                                                                        }
                                                                        {products[index].sku}
                                                                        <strong> x{products[index].qty}</strong><br/>

                                                                        {shipments[index].product?.name ? shipments[index].product.name : "-"}
                                                                    </FormLabel>
                                                                    <FormLabel
                                                                        className="col-3 col-form-label text-end">
                                                                        {shipments[index].product?.location ? shipments[index].product.location : "-"}
                                                                    </FormLabel>
                                                                    <ButtonGroup className="btn-group-sm col-4">
                                                                        <Button variant="outline-secondary"
                                                                                className="btn-sm"
                                                                                onClick={() => decrementQty(index)}><ArrowDownLeft/></Button>
                                                                        <FormControl name="qty"
                                                                                     data-index={index}
                                                                                     type="number"
                                                                                     value={submit[index].qty}
                                                                                     variant="outline-secondary"
                                                                                     onChange={handleInputChangeList}
                                                                                     className="rounded-0 border-secondary border-end-0 border-start-0 form-control-sm"/>
                                                                        <Button variant="outline-secondary"
                                                                                className="btn-sm rounded-end"
                                                                                onClick={() => incrementQty(index)}><ArrowUpRight/></Button>
                                                                    </ButtonGroup>

                                                                </FormGroup>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>

                                        {(packing && packing.length > 0) &&
                                            <div className="my-5">
                                                <span>Packing Material</span>
                                                <div className="card">
                                                    <ul className="list-group list-group-flush">
                                                        {packing.map((value, index) => {
                                                            return (<li className="list-group-item" key={index}>
                                                                <FormGroup className="row">

                                                                    <FormLabel
                                                                        className="col-4 col-form-label">
                                                                        {value.name}
                                                                        <strong> x{value.qty}</strong><br/>
                                                                    </FormLabel>
                                                                    <FormLabel
                                                                        className="col-6 col-form-label text-end">
                                                                        {value.location}
                                                                    </FormLabel>
                                                                    <ButtonGroup className="btn-group-sm col-2">
                                                                        <Button variant=""
                                                                                className="btn-sm"
                                                                                data-index={index}
                                                                                onClick={() => removeItemByIndex(index)}><Trash3
                                                                            className="text-danger"/></Button>
                                                                    </ButtonGroup>

                                                                </FormGroup>
                                                            </li>)
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        }

                                        {requirementsMeet
                                            ? <div className="my-5">

                                                {/*<div className="mb-5 row">*/}
                                                {/*    <FormLabel className="col-sm-4 col-form-label">Set*/}
                                                {/*        Location</FormLabel>*/}
                                                {/*    <div className="col-sm-8">*/}
                                                {/*        <input type="text"*/}
                                                {/*               className="form-control"*/}
                                                {/*               onKeyUp={handleKeyUp}/>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="text-center">
                                                    {(loadingSubmit === false) ?
                                                        <Button type="submit" variant="primary"
                                                                onClick={handleSubmit}>Submit</Button>
                                                        :
                                                        <Button className="btn btn-primary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status"
                                                              aria-hidden="true"></span>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                            : <div className="text-center my-5">
                                                <button type="submit"
                                                        className="btn btn-primary"
                                                        onClick={handleSubmit}
                                                        disabled> Submit
                                                </button>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <FooterComponent/>
        </Fragment>
    );
};

export default Admin;