import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Container, Form, FormCheck, FormGroup} from 'react-bootstrap'
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import axios from "axios";
import {useNavigate} from 'react-router-dom';

const ImportPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const {name} = useParams();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selected, setSelected] = useState({});
    const [columns, setColumns] = useState([]);
    const [fields, setFields] = useState(null);
    const [saveField, setSaveField] = useState(true);
    const navigate = useNavigate();
    const handleFileDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];

        // Check if file is .xls or .xlsx
        if (file.type !== 'application/vnd.ms-excel' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            console.log('Invalid file format. Please select a .xls or .xlsx file.');
            return;
        }

        setSelectedFile(file);

        const reader = new FileReader();
        reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, {type: 'array'});
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const headers = {};
            const columnsList = [];

            for (const cell in worksheet) {
                const cellRef = XLSX.utils.decode_cell(cell);
                if (cellRef.r === 0) { // Check if cell is in first row
                    const header = XLSX.utils.format_cell(worksheet[cell]).trim();
                    headers[header] = header;
                }
            }

            // Get list of column headers
            for (const header in headers) {
                columnsList.push(headers[header]);
            }

            setColumns(columnsList);

            const formData = new FormData();
            formData.append('map', columnsList);
            const response = await axios.post(apiUrl + '/table/map', formData);
            if (response.data.status === true)
                setSelected(JSON.parse(response.data.data.map))

        };

        reader.readAsArrayBuffer(file);

        await handleGetFields();
    };

    const handleGetFields = async () => {
        axios.get(apiUrl + '/table/fields/' + name).then(r => {
            if (r.data.status) setFields(r.data.data.slice(1))
        })
        console.log(selectedFile);
    }

    const handleSelectChange = (event) => {
        const {name, value} = event.target;
        setSelected((prevSelected) => {
            return {
                ...prevSelected,
                [name]: value,
            };
        });
    };

    // const headers = (fields !== null) ? fields.map((item, index) =>
    //     <option key={index}>{item.name}</option>
    // ) : null;

    const handleImport = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('name', name);
        formData.append('save', saveField);
        formData.append('columns', columns);
        formData.append('data', JSON.stringify(selected));

        const response = await axios.post(apiUrl + '/table/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data.status === true)
            navigate(`/admin/${name}`, {replace: true});

        console.log(response);
    }

    const handleChangeSave = (event) => {
        setSaveField(event.target.checked);
    }

    console.log("SELECTED", selected);


    return (
        <>
            <HeaderComponent/>
            <Container>
                <Dropzone onDrop={handleFileDrop} accept=".xls,.xlsx">
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} style={{
                            border: '1px solid black',
                            height: '200px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <input {...getInputProps()} />
                            <p>Drag and drop a .xls or .xlsx file here or click to select a file.</p>
                        </div>
                    )}
                </Dropzone>
                {selectedFile && (
                    <div className="my-5">
                        <h3>Selected file: {selectedFile.name}</h3>
                        <h4>Columns:</h4>

                        {fields && fields.map((field, i) => (
                            <FormGroup key={i}>
                                <div className="row my-2">
                                    <div className="col-md-6">
                                        <Form.Label>{field.name}</Form.Label>
                                    </div>
                                    <div className="col-md-6">
                                        <select name={field.name} className="form-select"
                                                aria-label="Default select example"
                                                onChange={handleSelectChange}
                                                value={selected[field.name] || ""}>
                                            <option>Open this select menu</option>
                                            {columns.map((column, index) => (
                                                <option key={index} value={column}>{column}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                        ))}
                        <div className="form-check form-switch">
                            <FormCheck id="flexSwitchCheckChecked" name="save" onChange={handleChangeSave}
                                       defaultChecked/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked"> Save map</label>
                        </div>

                        <Button className="mt-5" onClick={handleImport}>Import</Button>
                    </div>
                )}
            </Container>
            <FooterComponent/>
        </>
    );
};

export default ImportPage;
