import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';


const ProtectedRoute = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/login', {replace: true});
    }, []);
}

export default ProtectedRoute;