import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Home from "../pages/Home";
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';
import Logout from "../pages/Logout";
import Admin from '../pages/Admin/Index';
import Import from "../pages/Admin/Import";
import Products from "../pages/Admin/Products";
import Shipments from "../pages/Admin/Shipments";
import PackingMaterial from "../pages/Admin/PackingMaterial";
import Reports from "../pages/Admin/Reports";
import ProtectedRoute from "../helpers/ProtectedRoute";

function RoutesUrl() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        setIsLoggedIn(!!(localStorage.getItem('authToken')));
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <>
                    {(isLoggedIn === true) && <>
                        <Route path="/admin" element={(isLoggedIn) ? <Admin/> : <ProtectedRoute/>}/>
                        <Route path="/admin/products" element={(isLoggedIn) ? <Products/> : <ProtectedRoute/>}/>
                        <Route path="/admin/shipments" element={(isLoggedIn) ? <Shipments/> : <ProtectedRoute/>}/>
                        <Route path="/admin/reports" element={(isLoggedIn) ? <Reports/> : <ProtectedRoute/>}/>
                        <Route path="/admin/packing_material" element={(isLoggedIn) ? <PackingMaterial/> : <ProtectedRoute/>}/>
                        <Route path="/admin/import/:name" element={(isLoggedIn) ? <Import/> : <ProtectedRoute/>}/>
                    </>}

                    <Route path="/" element={<Home/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </>
            </Routes>
        </BrowserRouter>
    )
        ;
}

export default RoutesUrl;
