import React, {Fragment} from 'react';
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import TableComponent from "./components/TableComponent";

const PackingMaterial = () => {
    return (
        <Fragment>
            <HeaderComponent/>
            <main>
                <section>
                    <div className="container">
                        <TableComponent table="packing_material"/>
                    </div>
                </section>
            </main>
            <FooterComponent/>
        </Fragment>
    );
};

export default PackingMaterial;