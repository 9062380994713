import React, {Fragment} from 'react';
import './App.css'
import RoutesUrl from './config/routes';

function App() {
    return (
        <Fragment>
            <RoutesUrl/>
        </Fragment>
    );
}

export default App;