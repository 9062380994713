import React, {Fragment} from 'react';
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";

const Reports = () => {
    return (
        <Fragment>
            <HeaderComponent/>
            <main>
                <section>
                    <div className="container">

                    </div>
                </section>
            </main>
            <FooterComponent/>
        </Fragment>
    );
};

export default Reports;