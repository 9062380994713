import React, {useState} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {
        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');
        const [loadingLogin, setLoadingLogin] = useState(false);

        const apiUrl = process.env.REACT_APP_API_URL
        const navigate = useNavigate();
        const handleLogin = (event) => {
            event.preventDefault();
            setLoadingLogin(true);
            axios.post(apiUrl + '/auth/login', {username: username, password: password}).then(r => {
                if (r.data.status === true) {
                    localStorage.setItem('authToken', r.data.token);
                    navigate('/admin', {replace: true});
                    window.location.reload();
                }
                setLoadingLogin(false);
            })
        }

        return (
            <Container className="mt-5">
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="card">
                            <div className="card-header"><h4>Login</h4></div>
                            <div className="card-body">
                                <Form onSubmit={handleLogin}>
                                    <Form.Group controlId="formBasicUsername">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control type="text" placeholder="Enter username" value={username}
                                                      onChange={(e) => setUsername(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter password" value={password}
                                                      onChange={(e) => setPassword(e.target.value)}/>
                                    </Form.Group>

                                    <div className="mt-3">
                                    {(loadingLogin === false)
                                        ? <Button variant="primary" type="submit">Login</Button>
                                        :
                                        <Button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"></span>
                                            <span className="visually-hidden">Loading...</span>
                                        </Button>
                                    }
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
;

export default LoginPage;
