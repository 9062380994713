import React from 'react';

const FooterComponent = () => {
    return (
        <footer>

        </footer>
    );
};

export default FooterComponent;
