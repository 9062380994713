import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

function NotFound() {
    return (
        <Container className="my-5">
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <h1 className="text-center mb-4">Oops!</h1>
                    <p className="text-center mb-4">The page you are looking for could not be found.</p>
                    <div className="d-flex justify-content-center">
                        <Link to="/">
                            <Button variant="primary">Go Home</Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default NotFound;
